@import "~bootstrap/scss/bootstrap";

/* parisienne-regular - latin */
@font-face {
  font-family: 'Parisienne';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/parisienne-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/parisienne-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/parisienne-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/parisienne-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/parisienne-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/parisienne-v8-latin-regular.svg#Parisienne') format('svg'); /* Legacy iOS */
}

/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/raleway-v22-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/raleway-v22-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/raleway-v22-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/raleway-v22-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/raleway-v22-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/raleway-v22-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}



.App {
  text-align: center;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.btn-primary {
  background-color: #ff92c2;
  border-color: #ff92c2;
  border-radius: 8px;
}

.btn-primary:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 8px;
  color: #ff92c2;
}

.btn-primary:focus {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 8px;
  color: #ff92c2;
}

.mainContent {
  height: 100vh;
}

.imageContainer{
  max-height: 100vh;
}

@media (min-width: 768px) {
  .mainContent {
    height: 100vh;
  }

  .imageContainer{
    max-height: 100vh;
  }
}

@media (min-width: 992px) {
  .mainContent {
    height: 90vh;
  }

  .imageContainer {
    max-height: 70vh;
  }
}
